import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import FormHelperText from "@mui/material/FormHelperText";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import Stack from "@mui/material/Stack";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { IconButton, CircularProgress } from "@mui/material";
import { EditLessonApi } from "src/DAL/lessons/lessons";
import { TimePicker } from "@mui/lab";
import { s3baseUrl } from "src/config/config";
import TinyEditor from "src/components/ckeditor/Ckeditor";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const Input = styled("input")({
  display: "none",
});

export default function CatagoryEditLesson() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const params = useParams();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [file, setProfileImage] = useState();
  const [audioFile, setAudio] = useState();
  const [fileNew, setProfileImageNew] = useState(false);
  const [oldAudioFile, setOldAudio] = useState();
  const [detailDescriptionCk, setDetailDescriptionCk] = useState("");

  const [inputs, setInputs] = useState({
    title: "",
    status: "",
    programStatus: "",
    showStatus: "",
    image: {},
    accessType: "",
    videoUrl: "",
    short_description: "",
    detailed_description: "",
    video_show_Status: "",
    order: "",
    video_duration: "",
  });

  const fileChangedHandler = (e) => {
    setProfileImage(URL.createObjectURL(e.target.files[0]));
    setProfileImageNew(true);
    setInputs({
      ...inputs,
      ["image"]: e.target.files[0],
    });
  };
  const audioFileChange = (e) => {
    setAudio(e.target.files[0]);
  };

  const handldeDeleteAudio = () => {
    setAudio();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const originalDate = new Date(inputs.video_duration);
    const hours = originalDate.getHours().toString().padStart(2, "0");
    const minutes = originalDate.getMinutes().toString().padStart(2, "0");
    const seconds = originalDate.getSeconds().toString().padStart(2, "0");
    const formattedTime = `${hours}:${minutes}:${seconds}`;
    const formData = new FormData();
    formData.append("title", inputs.title);
    formData.append("short_description", inputs.short_description);
    formData.append("detailed_description", detailDescriptionCk);
    formData.append("status", inputs.programStatus);
    formData.append("landing_lesson_video_url", inputs.videoUrl);
    formData.append("order", inputs.order);
    formData.append("lesson_duration", formattedTime);

    if (audioFile) {
      formData.append("audio_file", audioFile);
    } else {
    }
    if (fileNew == true) {
      formData.append("main_image", inputs.image);
    }
    if (state.category_name) {
      formData.append("category", state?.category_name._id);
    }

    const result = await EditLessonApi(formData, params?.detail_slug);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      navigate(-1);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  useEffect(() => {
    if (state) {
      setOldAudio(state.audio_file);
      setDetailDescriptionCk(state.detailed_description);
      const timeParts = state.lesson_duration.split(":");
      const date = new Date(
        0,
        0,
        0,
        parseInt(timeParts[0]),
        parseInt(timeParts[1]),
        parseInt(timeParts[2])
      );

      setInputs((prevState) => ({
        ...prevState,
        ["title"]: state.title,
        ["status"]: state.status,
        ["programStatus"]: state.status,
        ["videoUrl"]: state.landing_lesson_video_url,
        ["short_description"]: state.short_description,
        ["detailed_description"]: state.detailed_description,
        ["video_duration"]: date,
        ["image"]: state.lesson_images.thumbnail_1,
        ["order"]: state.order,
      }));
    }
  }, []);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container">
      <div className="row mobile-margin display-flex">
        <div className="col-12">
          <IconButton
            className="back-screen-button mb-4"
            onClick={() => navigate(-1)}
          >
            <ArrowBackIcon />
          </IconButton>
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <h2>Edit Lesson</h2>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Lesson Name*"
              variant="outlined"
              fullWidth
              name="title"
              value={inputs.title}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Stack spacing={3}>
                <TimePicker
                  ampm={false}
                  openTo="hours"
                  views={["hours", "minutes", "seconds"]}
                  inputFormat="HH:mm:ss"
                  mask="__:__:__"
                  label="Duration"
                  value={inputs.video_duration}
                  onChange={(newValue) => {
                    setInputs((prevState) => ({
                      ...prevState,
                      ["video_duration"]: newValue,
                    }));
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </Stack>
            </LocalizationProvider>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Landing Lesson Video Url"
              variant="outlined"
              fullWidth
              name="videoUrl"
              value={inputs.videoUrl}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Order *"
              variant="outlined"
              fullWidth
              name="order"
              value={inputs.order}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Lesson Status *
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="programStatus"
                value={inputs.programStatus}
                label="Programme Status *"
                onChange={handleChange}
              >
                <MenuItem value="true">Active</MenuItem>
                <MenuItem value="false">Inactive</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
            <div className="row w-100 div-style ms-0 pt-0">
              <div className="col-3">
                <p className="">Upload Audio</p>
                <FormHelperText className="pt-0">
                  Audio mp3 (max 200mb)
                </FormHelperText>
              </div>
              <div className="col-4">
                {audioFile && (
                  <DeleteIcon
                    onClick={handldeDeleteAudio}
                    className="mt-3 icon-color"
                  />
                )}
                <p className="text-secondary">{audioFile && audioFile.name}</p>
              </div>
              <div className="col-5 text-end pt-2">
                <label htmlFor="audio">
                  <Input
                    accept="audio/mp3,audio/*;capture=microphone"
                    id="audio"
                    multiple
                    name="audio"
                    type="file"
                    onChange={audioFileChange}
                  />

                  <Button
                    className="small-contained-button mt-3"
                    startIcon={<FileUploadIcon />}
                    component="span"
                  >
                    Upload
                  </Button>
                </label>
              </div>
            </div>
            <p className="text-secondary">{audioFile && audioFile.name}</p>
            {oldAudioFile && (
              <audio
                className="w-100"
                src={s3baseUrl + oldAudioFile}
                controls
              />
            )}
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
            <div className="row w-100 div-style ms-0 pt-0">
              <div className="col-4">
                <p className="">Upload Image *</p>
                <FormHelperText className="pt-0">
                  Image Size(1000 X 670) ("JPG", "JPEG", "PNG","WEBP")
                </FormHelperText>
              </div>
              <div className="col-3 text-center ps-5">
                {file ? (
                  <img src={file} height="50" />
                ) : (
                  <img
                    className="image-border"
                    src={s3baseUrl + inputs.image}
                    height="50"
                  />
                )}
              </div>

              <div className="col-5 text-end pt-2">
                <label htmlFor="contained-button-file">
                  <Input
                    accept="image/*"
                    id="contained-button-file"
                    multiple
                    type="file"
                    name="image"
                    onChange={fileChangedHandler}
                  />

                  <Button
                    className="small-contained-button"
                    startIcon={<FileUploadIcon />}
                    component="span"
                  >
                    Upload
                  </Button>
                </label>
              </div>
            </div>
            {inputs.image?.name == "" ? (
              ""
            ) : (
              <p className="text-secondary">{inputs.image?.name}</p>
            )}
          </div>
          <div className="col-12 mt-5">
            <FormControl fullWidth>
              <TextField
                id="outlined-multiline-flexible"
                label="Short Description *"
                multiline
                rows={6}
                name="short_description"
                value={inputs.short_description}
                onChange={handleChange}
              />
              <FormHelperText>Maximum limit 500 characters</FormHelperText>
            </FormControl>
          </div>
          <div className="col-12 mt-5">
            <h4>Detail Description *</h4>

            <TinyEditor
              setDetailDescription={setDetailDescriptionCk}
              detailDescriptionCk={detailDescriptionCk}
            />
          </div>
          <div className="text-end mt-4">
            <button className="small-contained-button">Submit</button>
          </div>
        </div>
      </form>
    </div>
  );
}
