import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Avatar, Tooltip } from "@mui/material";
import { lesson_list_by_category_api } from "src/DAL/lessons/lessons";
import { useEffect, useState } from "react";
import { s3baseUrl } from "src/config/config";
import { CircularProgress } from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import { makeStyles } from "@mui/styles";
import ActiveLastBreadcrumb from "src/components/ActivePaths";
import CustomMUITable from "src/components/CustomMUITable";
import { dd_date_format } from "src/utils/constant";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import { deleteLessonApi } from "src/DAL/lessonDocument/LessonDocument";
import { useSnackbar } from "notistack";

// ----------------------------------------------------------------------
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

// ----------------------------------------------------------------------

export default function CatagoryessonListing() {
  const params = useParams();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const { state } = useLocation();
  const [lesson, setLesson] = useState([]);
  const [categoryName, setCategory] = useState({});
  const [pageCount, setPageCount] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [totalPages, setTotalPages] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [totalCount, setTotalCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [deleteDoc, setDeleteDoc] = useState("");
  const [openDelete, setOpenDelete] = useState(false);

  let breadCrumbMenu = [
    {
      title: "Catagory",
      navigation: `/catagory`,
      active: false,
    },
    {
      title: `${categoryName?.category_name}`,
      active: true,
    },
  ];
  const searchFunction = () => {
    setPage(0);
    setPageCount(1);
    getLessonsList();
  };

  const getLessonsList = async () => {
    setIsLoading(true);

    let search_keyword =
      localStorage.getItem("catagroy_search_text") == null
        ? searchText
        : localStorage.getItem("catagroy_search_text");
    if (search_keyword !== null) {
      setSearchText(search_keyword);
    }

    let result = await lesson_list_by_category_api(
      params?.catagory_id,
      page,
      rowsPerPage,
      search_keyword
    );
    if (result.code == 200) {
      setIsLoading(false);
      setLesson(result.lesson);
      setCategory(result.category);
      setTotalCount(result.total_count);
      setTotalPages(result.total_pages);
    }
  };
  const handleDetail = (value) => {
    navigate(`/catagory/catagory-lessons-detail/${value.lesson_slug}`, {
      state: value,
    });
  };

  const handleEdit = (value) => {
    navigate(`/catagory/edit-lesson/${value.lesson_slug}`, {
      state: { ...value, category_name: categoryName },
    });
  };

  const handleAgreeDelete = (value) => {
    setDeleteDoc(value);
    setOpenDelete(true);
  };

  const handleDelete = async (value) => {
    setOpenDelete(false);
    setIsLoading(true);
    const result = await deleteLessonApi(deleteDoc?.lesson_slug);
    if (result.code === 200) {
      getLessonsList();
      enqueueSnackbar(result.message, { variant: "success" });
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
    {
      label: "View Detail",
      icon: "eva:eye-fill",
      handleClick: handleDetail,
    },
  ];

  const handleChangePages = (event, newPage) => {
    if (newPage <= 0) {
      setPage(0);
      setPageCount(1);
    } else {
      setPage(newPage - 1);
      setPageCount(newPage);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (newPage <= 0) {
      setPageCount(1);
    } else {
      setPageCount(newPage + 1);
    }
  };

  const handleChangeAdd = () => {
    navigate(`/catagory/add-lesson`, {
      state: state,
    });
  };

  useEffect(() => {
    getLessonsList();
  }, [rowsPerPage, page]);

  const TABLE_HEAD = [
    { id: "action", label: "Action", alignRight: false, type: "action" },

    { id: "number", label: "#", alignRight: false, type: "number" },
    {
      id: "title",
      label: "Title",
      renderData: (row) => {
        const iconStyle = {
          color: "blue !important",
        };
        let title = "N/A";
        if (row.title) {
          title = row.title;
        }
        return (
          <div
            onClick={() =>
              navigate(`/catagory/catagory-lessons-detail/${row.lesson_slug}`, {
                state: row,
              })
            }
            className="d-flex pointer"
          >
            <Tooltip
              title={row.status === false ? "Inactive" : "Active"}
              className="pointer"
            >
              <CircleIcon
                style={iconStyle}
                className={`transation-status-icon ${
                  row.status === false ? "Inactive-classs" : "Active-class"
                } `}
              />
            </Tooltip>

            <div>
              <p className="mb-0 " style={{ cursor: "pointer" }}>
                {title}
              </p>
            </div>
          </div>
        );
      },
    },
    {
      id: "image",
      label: "image",
      renderData: (row) => {
        return (
          <Avatar
            src={
              row?.lesson_images?.thumbnail_1
                ? s3baseUrl + row?.lesson_images?.thumbnail_1
                : ""
            }
            alt={row.lesson_slug}
          />
        );
      },
    },
    { id: "order", label: "Order", alignRight: false },
    {
      id: "createdAt",
      label: "Created At",
      alignRight: false,
      renderData: (row) => {
        let createdAt = "N/A";
        if (row.createdAt) {
          createdAt = dd_date_format(row.createdAt);
        }
        return <p className="mb-0">{createdAt}</p>;
      },
    },
  ];

  localStorage.setItem("category_name", categoryName?.category_name);
  localStorage.setItem("catagory_id", params?.catagory_id);

  useEffect(() => {
    localStorage.setItem("catagroy_search_text", searchText);
    if (searchText.length < 1) {
      localStorage.setItem("catagroy_search_text", "");
    } else {
      localStorage.setItem("catagroy_search_text", searchText);
    }
  }, [searchText]);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete ?"}
        handleAgree={handleDelete}
      />
      <div className="container">
        <div className="row mobile-margin display-flex">
          <div>
            <ActiveLastBreadcrumb breadCrumbMenu={breadCrumbMenu} />
          </div>
        </div>
        <div className="text-end">
          <button className="small-contained-button" onClick={handleChangeAdd}>
            Add Lesson
          </button>
        </div>
        <div className="row mt-3">
          <CustomMUITable
            TABLE_HEAD={TABLE_HEAD}
            data={lesson}
            MENU_OPTIONS={MENU_OPTIONS}
            className="card-with-background"
            pageCount={pageCount}
            totalPages={totalPages}
            handleChangePages={handleChangePages}
            custom_pagination={{
              total_count: totalCount,
              rows_per_page: rowsPerPage,
              page: page,
              handleChangePage: handleChangePage,
              onRowsPerPageChange: handleChangeRowsPerPage,
            }}
            custom_search={{
              searchText: searchText,
              setSearchText: setSearchText,
              handleSubmit: searchFunction,
            }}
            pagePagination={true}
          />
        </div>
      </div>
    </>
  );
}
