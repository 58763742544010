import * as React from "react";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import FormHelperText from "@mui/material/FormHelperText";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { IconButton, CircularProgress } from "@mui/material";
import { useEffect } from "react";
import {
  ParentCatagory,
  addCatagory,
  editcatagoryApi,
  page_detail_by_id,
  programmes_catagory,
} from "src/DAL/WebsitePages/websitepages";
import { s3baseUrl } from "src/config/config";
import {
  addBenefitApi,
  benefitsDetailApi,
  updateBenefitsApi,
} from "src/DAL/WebsitePages/benefitsApi";
import MUIAutocomplete from "src/components/GeneralComponents/MUIAutocomplete";
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));
const Input = styled("input")({
  display: "none",
});
export default function AddOrCatagories() {
  const navigate = useNavigate();
  const classes = useStyles();
  const { state } = useLocation();
  const [formType, setFormType] = useState("ADD");
  const { enqueueSnackbar } = useSnackbar();
  const [moduleData, setModuleData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const params = useParams();
  const [file, setProfileImage] = React.useState();
  const [oldfile, setoldProfileImage] = React.useState();
  const [pageData, setPageData] = useState({});
  const [timeCk, setTimeCk] = useState("");
  const [grandParentCategoryList, setgrandParentCategoryList] = useState([]);
  const [ParentCategory, setParentCategory] = useState([]);
  const [programList, setprogramList] = useState([]);
  const [reviewTopicCk, setReviewTopicCk] = useState("");
  const [catagoriesList, setcatagoriesList] = useState([]);
  const module_actual_name = "benefits";
  const [date, setDate] = React.useState(new Date());
  const [inputs, setInputs] = React.useState({
    status: true,
    image: {},
    category_name: "",
    grandParentCategory: null,
    parentCategory: null,
    program: "",
    order: "",
  });
  const fileChangedHandler = (e) => {
    setProfileImage(URL.createObjectURL(e.target.files[0]));
    setInputs({
      ...inputs,
      ["image"]: e.target.files[0],
    });
    setoldProfileImage("");
  };
  const handleChangeOthers = (event, name) => {
    setInputs((values) => ({ ...values, [name]: event }));
    if (name == "grandParentCategory") {
      setInputs((values) => ({
        ...values,
        ["parentCategory"]: null,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const formData = new FormData();

    formData.append("category_name", inputs.category_name);
    formData.append("status", inputs.status);
    formData.append("program", inputs.program);
    formData.append("category_image", inputs.image);
    formData.append(
      "grandParentCategory",
      inputs.grandParentCategory ? inputs.grandParentCategory._id : ""
    );
    formData.append(
      "parentCategory",
      inputs.parentCategory ? inputs.parentCategory._id : ""
    );

    if (formType === "EDIT") {
      formData.append("order", inputs.order);
    }
    const result =
      formType == "ADD"
        ? await addCatagory(formData)
        : await editcatagoryApi(formData, params?.id);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      navigate(-1);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const getPageDetail = async () => {
    const result = await page_detail_by_id(params.page_id);
    if (result.code == 200) {
      handlePageDetail(result.sale_page);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  const getProgrammList = async () => {
    const result = await programmes_catagory();
    if (result.code == 200) {
      setprogramList(result.program);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  const getParentCatagory = async (id) => {
    const result = await ParentCatagory(id);
    if (result.code == 200) {
      setgrandParentCategoryList(result.category);

      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };
  const handleChangePrograms = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({
      ...values,
      ["grandParentCategory"]: null,
    }));
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const getFaqDetailData = async () => {
    setIsLoading(true);
    const result = await benefitsDetailApi(params.success_id);
    if (result.code == 200) {
      setFormType("EDIT");
      setInputs(result.success_schedule);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handlePageDetail = (data) => {
    setPageData(data);
    const get_module_info = data?.module_info?.filter(
      (item) => item.module_actual_name == module_actual_name
    )[0];
    setModuleData(get_module_info?.module_replica_info);
  };

  useEffect(() => {
    getProgrammList();
    if (params && params.id) {
      if (state) {
        setInputs((values) => ({
          ...values,
          ["status"]: state.status,
          ["category_name"]: state.category_name,
          ["program"]: state.program._id,
          ["order"]: state.order,
          ["grandParentCategory"]: state.grandParentCategory,
          ["parentCategory"]: state.parentCategory,
        }));

        setoldProfileImage(state?.table_avatar?.src);
        setFormType("EDIT");
      }
    }
  }, []);

  useEffect(() => {
    if (inputs.program) {
      getParentCatagory(inputs.program);
    }
  }, [inputs.program]);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <div className="container">
      <div className="row mobile-margin display-flex">
        <div className="col-12 display-flex mb-4">
          <IconButton
            className="back-screen-button"
            onClick={() => navigate(-1)}
          >
            <ArrowBackIcon />
          </IconButton>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <h2>
            <h2>{`${formType === "ADD" ? "Add" : "Edit"} Catagory`}</h2>
          </h2>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Name"
              variant="outlined"
              fullWidth
              required
              name="category_name"
              value={inputs.category_name}
              onChange={handleChange}
            />
          </div>
          {formType !== "ADD" && (
            <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
              <TextField
                id="outlined-basic"
                label="Order"
                variant="outlined"
                fullWidth
                required
                name="order"
                value={inputs.order}
                onChange={handleChange}
              />
            </div>
          )}
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <FormControl fullWidth required>
              <InputLabel id="demo-simple-select-label">Programmes </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="program"
                value={inputs.program}
                label="Programmes"
                onChange={handleChangePrograms}
              >
                {programList.map((item) => {
                  return <MenuItem value={item._id}>{item.title}</MenuItem>;
                })}
              </Select>
            </FormControl>
          </div>{" "}
          {grandParentCategoryList.length > 0 && (
            <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
              <MUIAutocomplete
                inputLabel="Grand Parent Category"
                selectedOption={inputs.grandParentCategory}
                setSelectedOption={(e) => {
                  handleChangeOthers(e, "grandParentCategory");
                }}
                optionsList={grandParentCategoryList}
                autoComplete="new-password"
                name="category_name"
              />
            </div>
          )}
          {inputs.grandParentCategory && (
            <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
              <MUIAutocomplete
                inputLabel="Parent Category"
                selectedOption={inputs.parentCategory}
                setSelectedOption={(e) => {
                  handleChangeOthers(e, "parentCategory");
                }}
                optionsList={
                  inputs.grandParentCategory.parentCategory
                    ? inputs.grandParentCategory.parentCategory
                    : []
                }
                autoComplete="new-password"
                name="category_name"
              />
            </div>
          )}
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <FormControl fullWidth required>
              <InputLabel id="demo-simple-select-label">Status </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="status"
                value={inputs.status}
                label="Status"
                onChange={handleChange}
              >
                <MenuItem value={true}>Active</MenuItem>
                <MenuItem value={false}>Inactive</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
            <div className="row w-100 div-style ms-0 pt-0">
              <div className="col-5">
                <p className="">Upload Image* </p>
                <FormHelperText className="pt-0">
                  Image Size(1000 X 670) ("JPG", "JPEG", "PNG","WEBP")
                </FormHelperText>
              </div>
              <div className="col-2">
                {oldfile ? (
                  <img className="image-border" src={oldfile} height="50" />
                ) : (
                  file && (
                    <img className="image-border" src={file} height="50" />
                  )
                )}
              </div>
              <div className="col-5 text-end pt-2">
                <label htmlFor="contained-button-file">
                  <Input
                    accept="image/*"
                    id="contained-button-file"
                    multiple
                    type="file"
                    name="image"
                    onChange={fileChangedHandler}
                  />

                  <Button
                    className="small-contained-button"
                    startIcon={<FileUploadIcon />}
                    component="span"
                  >
                    Upload
                  </Button>
                </label>
              </div>
            </div>
            {inputs?.image?.name == "" ? (
              ""
            ) : (
              <p className="text-secondary">{inputs?.image?.name}</p>
            )}
          </div>
          <div className="text-end mt-4">
            <button className="small-contained-button">
              {formType == "ADD" ? "Submit" : "Update"}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
